<template>
 <div class="headBody">
   <div class="headBody-inner">
     <router-link to="/index"><div class="logo"></div></router-link>
      <div class="nav-list">
        <el-menu :default-active="activeIndex"
                 class="el-menu-demo"
                 mode="horizontal"
                 @select="handleSelect"
                 :unique-opened="true"
                 text-color="#484848"
                 active-text-color="#4C6DFD"
        >
          <el-menu-item index="1">网站首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">产品超市</template>
              <el-menu-item index="2">
                <div class="products">
                  <router-link to="/liandongyun">
                   <div :class="productsIndex == '1'?'productsNav':'productsDiv'">
                     <img src="../assets/img/products1.png"/>
                     <div>
                       <div class="productsTitle">链动云</div>
                       <div class="productsText">业财一体化管理中台</div>
                     </div>
                   </div>
                  </router-link>
                  <router-link to="/lianyunpiao">
                    <div :class="productsIndex == '2'?'productsNav':'productsDiv'">
                      <img src="../assets/img/products2.png"/>
                      <div>
                        <div class="productsTitle">链云票</div>
                        <div class="productsText">专为企业开票场景设计</div>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/lianjiebao">
                    <div :class="productsIndex == '3'?'productsNav':'productsDiv'">
                      <img src="../assets/img/products3.png"/>
                      <div>
                        <div class="productsTitle">链捷报</div>
                        <div class="productsText">解决企业费用业务</div>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/qianhuiduo">
                    <div :class="productsIndex == '4'?'productsNav':'productsDiv'">
                      <img src="../assets/img/products4.png"/>
                      <div>
                        <div class="productsTitle">钱会多</div>
                        <div class="productsText">企业可视化资金管理</div>
                      </div>
                    </div>
                  </router-link>
                  <router-link to="/ruziniu">
                    <div :class="productsIndex == '5'?'productsNav':'productsDiv'">
                      <img src="../assets/img/products5.png"/>
                      <div>
                        <div class="productsTitle">孺子牛</div>
                        <div class="productsText">大宗商品数字化平台</div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">咨询定制</template>
            <el-menu-item index="3">
              <div class="service">
                <router-link to="/other">
                  <div class="serviceDiv">
                    <img src="../assets/img/advisory1.png" style="width:66px;height:66px">
                    <div class="serviceTitle">产品构建</div>
                  </div>
                </router-link>
                <router-link to="/design">
                  <div class="serviceDiv">
                    <img src="../assets/img/advisory2.png" style="width:62px;height:62px">
                    <div class="serviceTitle">UX设计</div>
                  </div>
                </router-link>
                <router-link to="/other">
                  <div class="serviceDiv">
                    <img src="../assets/img/advisory3.png" style="width:66px;height:66px">
                    <div class="serviceTitle">软件开发</div>
                  </div>
                </router-link>
                <router-link to="/other">
                  <div class="serviceDiv">
                    <img src="../assets/img/advisory4.png" style="width:66px;height:66px">
                    <div class="serviceTitle">测试</div>
                  </div>
                </router-link>
                <router-link to="/other">
                  <div class="serviceDiv">
                    <img src="../assets/img/advisory5.png"  style="width:70px;height:70px">
                    <div class="serviceTitle">运维</div>
                  </div>
                </router-link>
              </div>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="4">公司简介</el-menu-item>
          <el-menu-item index="5">联系我们</el-menu-item>
        </el-menu>
      </div>
   </div>
 </div>
</template>

<script>
import axios from "axios";
import util from "@/libs/util.js";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import storage from "@/utils/storage";
export default {
  name: "headerNav",
  props: {
    activeIndex: {
      type: String,
      default: '1'
    },
    productsIndex: {
      type: String,
      default: '1'
    },
  },
  data () {
    return {
    };
  },
  mounted () {

  },
  methods: {
    handleSelect(key,type) {
      if (key == 1) {
        this.$router.push({
          name: "home",
        });
      }else if (key == 4) {
        this.$router.push({
          name: "aboutUs",
        });
      } else if (key == 5) {
        this.$router.push({
          name: "contact",
        });
      }
    },

    // let routeData = this.$router.resolve({
    //   path: 'contact'
    // });
    // window.open(routeData.href, '_blank');
  }
};
</script>
<style>
.el-menu--horizontal{
  left: 0!important;
  width: 100% !important;
 }
.el-menu--popup{
   box-shadow: none !important;
   margin-top: 0px !important;
   height: 206px !important;
 }
.el-menu-item:hover{
  color: #4C6DFD !important;
}
.el-submenu__title:hover{
  color: #4C6DFD !important;
}
.el-menu  .products{
  width:1200px;
  height:206px;
  margin:0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-menu  .products .productsDiv{
  width: 189px;
  height: 58px;
  background: #F6F8FF;
  border-radius: 8px;
  padding: 18px;
  display: flex;
  border: 1px solid #F6F8FF;
}
.el-menu  .products .productsDiv:hover{
  border-radius: 8px;
  padding: 18px;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(197,208,254,0.4);
  border: 1px solid #4C6DFD;
  color: #4C6DFD;
}
.el-menu  .products .productsDiv:hover .productsTitle{
  color: #4C6DFD;
}
.el-menu  .products .productsNav{
  width: 189px;
  height: 58px;
  border-radius: 8px;
  padding: 18px;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 0px 40px 0px rgba(197,208,254,0.4);
  border-radius: 8px;
  border: 1px solid #4C6DFD;
}
.el-menu  .products .productsNav img{
  width: 58px;
  height: 58px;
  margin-right: 5px;
}
.el-menu  .products .productsNav .productsTitle{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #4177FB;
  line-height: 33px;
  text-align: left;
  font-style: normal;
}
.el-menu  .products .productsNav .productsText{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  margin-top: 5px;
}
.el-menu  .products .productsDiv img{
  width: 58px;
  height: 58px;
  margin-right: 5px;
}
.el-menu  .products .productsDiv .productsTitle{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #050829;
  line-height: 33px;
  text-align: left;
  font-style: normal;
}
.el-menu  .products .productsDiv .productsText{
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 13px;
  color: #999999;
  line-height: 18px;
  text-align: left;
  font-style: normal;
  margin-top: 5px;
}
.el-menu  .service{
  width:1200px;
  height:206px;
  margin:0 auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
 }
.el-menu  .service  .serviceDiv{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #393939;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  margin-top: 15px;
  cursor:pointer;
}
.el-menu  .service  .serviceDiv .serviceTitle{
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #393939;
  line-height: 25px;
  text-align: center;
  font-style: normal;
  margin-top: 15px;
  cursor:pointer;
}
.el-menu  .service  .serviceDiv:hover .serviceTitle{
  color: #4C6DFD;
}

</style>
<style lang="scss" >
.headBody{
  width: 100%;
  height: 88px;
  background: #F8FAFF;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0; /* 定义元素距离视窗顶部的距离 */
  z-index: 999;
  .headBody-inner{
    margin:0 auto;
    text-align: center;
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo{
      width: 163px;
      height: 48px;
      background:url("../assets/img/logo.png") no-repeat center;
      background-size: 100% 100%;
    }
  }
  .nav-list{
    width:768px;
    height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 17px;
    color: #050829;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    cursor:pointer;
    .el-menu-demo{
      width:  100%;
      border:none;
      display: flex;
      height: 88px;
      background: #F8FAFF!important;
      justify-content: space-between;
      .el-menu-item{
        height: 88px!important;
        line-height:88px!important;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        color: #050829;
        text-align: left;
        font-style: normal;
        background: #F8FAFF!important;
        user-select: none;
        padding:0px 5px;
        margin: 0px 15px;
      }
      .el-submenu__title{
        height: 88px!important;
        line-height:88px!important;
        background: #F8FAFF!important;
        font-weight: 500;
        font-size: 16px;
        color: #050829;
        text-align: left;
        font-style: normal;
        user-select: none;
        padding:0px 5px;
        margin: 0px 15px;
      }

    }
  }

}
</style>